import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/peterkrajcovic/Documents/Web/GatsbyProject/globaltel_V5_actual/src/components/newsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>Odborník pre informatizáciu štátu: Ľudia sa sami pripravujú o možnosť podieľať sa navládnutí</h1>
    <p>
    Málokto vie, že stačí aby 15-tisíc občanov elektronicky, teda hoc aj z pohodlia svojej obývačky,
    podpísalo tzv. elektronickú hromadnú žiadosť, a tou sa musí vláda zaoberať. Pritom tento
    mechanizmus je občanom k dispozícii už od roku 2015. Vláda sa zaviazala, že všetky takéto
    žiadosti vezme v úvahu a prerokuje ich na svojom rokovaní a zaujme k nim stanovisko vo forme
    uznesenia. O tom, prečo túto možnosť, ktorá dáva občanom do ruky silnú zbraň na presadzovanie
    svojich záujmov, nevyužívame, sme hovorili s Ing. Rolandom Takácsom, PhD., biznis konzultantom,
    ktorý spolupracoval na tvorbe systému pre elektronické hromadné žiadosti.
    </p>
    <p>
    <strong>Koľko bolo doteraz podaných hromadných žiadostí?</strong>
    <br />
    Za skoro 5 rokov bola správne podaná iba jediná žiadosť, aj tá však nezískala dostatočnú
    podporu. Zoznam týchto žiadostí je na <a href="https://open.slovensko.sk/hromadneziadosti?tab=archiv" target="blank">https://open.slovensko.sk/hromadneziadosti?tab=archiv</a>
    </p>
    <p>
    <strong>Nie je dôvodom prílišná komplikovanosť podávania či zložité podpisovanie, nedostupné
        väčšine
        ľudí?</strong><br />
    Nemyslím si. Elektronickú hromadnú žiadosť môže podporiť ktokoľvek, kto má elektronickú poštu
    uvedením svojej e-mail adresy. Systém vyhodnocuje iba unikátnosť adresy, aby niekto nepodporil
    žiadosť viac krát a overuje existenciu adresy spätnou správou. Áno, máme obmedzenie pre tvorcu
    žiadosti, ten musí byť identifikovaný prostredníctvom občianskeho preukazu s čipom, ale treba si
    uvedomiť, že je to potrebné, rokovanie vlády k žiadosti vrátane prípravy nie je lacná záležitosť
    a nie je možné, aby takúto žiadosť podával napríklad Mickey Mouse.
    </p>
    <p>
    <strong>Prečo potom je podľa vás tak málo podaných žiadostí?</strong>
    <br />
    Tiež ma to prekvapuje, bolo predsa toľko protestov a iniciatív a nikto nevyužil túto legitímnu
    elektronickú formu. Jednou z príčin je určite slabá, v súčasnosti de-facto neexistujúca
    propagácia. Len veľmi málo ľudí vie o možnosti podať elektronickú hromadnú žiadosť. Príčinou
    môže byť tiež naša mentalita, ponadávame si v krčme, pomudrujeme a čakáme, kým za nás niekto
    niečo urobí.
    </p>
    <p>
    <strong>A nie je jednoduchšie organizovať takéto hlasovania cez sociálne siete, napríklad
        Facebook?</strong>
    <br />
    Vyskytli sa zo strany politikov pokusy o akési hlasovania cez sociálne siete, treba si však
    uvedomiť, že v takomto prípade podnety na hlasovanie zadáva výlučne majiteľ konta, ktorý nemusí
    jemu nepríjemné otázky na svoj profil vôbec zaradiť. Využívanie sociálnych sietí na hlasovanie
    môže tiež znižovať množstvo zapojených ľudí, používanie jednotlivých sociálnych sietí nedosahuje
    využívanie elektronickej pošty. A po tretie, nevidím dôvod, prečo by niekto mal naháňať reklamu
    a kliky komerčným spoločnostiam, ktoré sociálne siete prevádzkujú.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      